
//导入请求配置文件
import Request from "@/request/request.js"
import config from "@/request/config.js"

var api = {};


// 登录
api.login = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=loginById",data);
// 手机号登录
api.phonelogin = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=loginByPhone",data);

//佣金设置获取和提交
api.baseConfigDAL = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=baseConfigDAL",data);

//文件上传接口
api.fileUpload = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=fileUpload",data);

//招聘信息详情接口 修改 删除
api.entityDataHandle = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=entityDataHandle",data);


api.PublicAPP = data => Request.post(config.interfaces+"&WsAjaxBiz=PublicAPP&WsAjaxAction=entityDataHandle",data);

//商家招聘信息详情接口 修改 删除
api.entityDataHandles = data => Request.post(config.interfaces+"&WsAjaxBiz=Supplier&WsAjaxAction=entityDataHandle",data);


//首页类型管理
api.baseConfigDAL = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=baseConfigDAL",data);

//查看信息列表
api.fetchPageData = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=fetchPageData",data);

//查看招聘信息
api.launchAudit = data => Request.post(config.interfaces+"&WsAjaxBiz=Plater&WsAjaxAction=launchAudit",data);




//佣金设置
api.deptlist = data => Request.post(config.interfaces+"&WsAjaxBiz=ZTest&WsAjaxAction=dumb",data);



export default api










 