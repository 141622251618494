<template>
  <div class="login">
    <!-- 平台登录+++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
    <div :class="actives == 1?'register':'registers'" >
      <div class="register-box">
        <div class="log">
          <img src="../assets/image/logos.png" alt="" />
        </div>
        <div class="text">平台登录</div>
        <!-- 表单 -->
        <div class="forms">
          <el-form ref="form" :model="account" label-width="0px" :rules="rules">
            <div class="forms-box">
              <el-form-item prop="account">
                <el-input
                  v-model="account.account"
                  :maxlength="16"
                  placeholder="请输入后台账号"
                ></el-input>
              </el-form-item>
            </div>
            <div class="forms-box">
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="account.password"
                  :maxlength="16"
                  placeholder="请输入登录密码"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
           <div class="qiehuan"><p><el-checkbox v-model="checked">记住密码</el-checkbox></p><p @click="shanghuqiehuan()"><i class="el-icon-sort"></i> 切换到商户登录</p></div>
          <div class="form-btn">
            <el-button type="primary" class="biy-icon" plain @click="loginto()">登录</el-button>
          </div>
        </div>
      </div>
      <div class="register-botton">技术支持 <span>沃思电子商务</span></div>
    </div>
    <!-- 商户登录+++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
    <div :class="actives == 2?'register':'registers'">
      <div class="register-box">
        <div class="log">
          <img src="../assets/image/logos.png" alt="" />
        </div>
        <div class="text">商户登录</div>
        <!-- 表单 -->
        <div class="forms">
          <el-form ref="form" :model="phonecode" label-width="0px" :rules="rules">
            <div class="forms-box">
              <el-form-item prop="account">
                <el-input
                  v-model="phonecode.phone"
                  :maxlength="16"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </div>
            <div class="forms-box yanzheng">
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="phonecode.code"
                  :maxlength="16"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item> 
              <el-button type="primary" class="denglu-biy" plain :disabled="disable" :class="{ codeGeting:isGeting }" @click="shlogintos()">{{getCode}}</el-button>
            </div>
          </el-form>
           <div class="qiehuan"><p @click="yonghuqiehuan()"><i class="el-icon-sort"></i> 切换到平台登录</p></div>
          <div class="form-btn">
            <el-button type="primary" class="biy-icon" plain @click="shloginto()">登录</el-button>
          </div>
        </div>
      </div>
      <div class="register-botton">技术支持 <span>沃思电子商务</span></div>
    </div>

      <div class="bah"> <a target="_blank" href="https://beian.miit.gov.cn/">Copyright© 2021-2022 备案号：津ICP备2021007726号-1</a></div>
  </div>
</template>
<script>
import apiurl from "@/api/apiurl/apiurl.js"
export default {
  data() {
    return {
      // 记住密码
      checked:true,
      // 切换登录
      actives:1,
      // 用户登录
      account: {
        account: this.$common.baseGetuSername(),
        password: this.$common.baseGetPassword(),
      },
      // 商家登录
      phonecode: {
        phone: "",
        code: "",
      },
      // 
       getCode: '获取验证码',
        isGeting: false,
         count: 60,
      disable: false,

      rules: {
        account: [
          // {
          //   required: true,
          //   pattern: /^[a-zA-Z0-9_-]{1,16}$/,
          //   message: "包含非法字符",
          //   trigger: "blur",
          // },
        ],
        password: [
          // {
          //   required: true,
          //   pattern: /^[a-zA-Z0-9_-]{1,16}$/,
          //   message: "包含非法字符",
          //   trigger: "blur",
          // },
        ],
      },
    };
  },

    created(){},

  methods: {
  async  loginto(){
    		let res = await apiurl.login({
            loginName:this.account.account,//登录账号
            loginPwd:this.account.password,//登录密码
        })
         if (res.ErrMsg == ''){
          //  缓存登录密码
              if (this.checked) {
                 this.$common.baseSavePassword(this.account.password);//缓存登录密码
              }else{
                  this.$common.baseSavePassword(null);//缓存登录密码
              }

            this.$common.baseSaveToken(res.Result.forendToken);//缓存token
            this.$common.baseSaveRoute(res.Result);//缓存路由
            this.$common.baseSaveSername(res.Result.bizUserInfo.login_name);//缓存用户名

            this.$common.baseSaveSeparate(this.actives);//区分开商户和用户
            this.$router.push({path:'/home'})
             this.$alert('登录成功', '消息提示', {confirmButtonText: '确定'});
         }else{
              this.$alert('请检查账号密码', '登录失败', {confirmButtonText: '确定'});
         }

    },
// 获取验证码
      async  shlogintos(){

     var countDown = setInterval(() => {
        if (this.count < 1) {
          this.isGeting = false
          this.disable = false
          this.getCode = '获取验证码'
          this.count = 6
          clearInterval(countDown)
        } else {
          this.isGeting = true
          this.disable = true
          this.getCode = this.count-- + 's后重发'
        }
      }, 500)
        		let res = await apiurl.phonelogin({ 
            phone:this.phonecode.phone,//登录账号
            code:'',//发送验证码不用传递此参数/验证验证码填充此参数 
        })
        if (res.ErrMsg == '') {
             this.$alert('发送成功', '消息提示', {confirmButtonText: '确定'});
        }else{
           this.$alert(res.ErrMsg, {confirmButtonText: '确定'});
        }

      },
    // 手机号登录
  async  shloginto(){
        if (this.phonecode.code == undefined) {
      this.$alert('验证码为空,请先获取验证码', {confirmButtonText: '确定'});
        }else{
    		let res = await apiurl.phonelogin({ 
            phone:this.phonecode.phone,//登录账号
            code:this.phonecode.code,//发送验证码不用传递此参数/验证验证码填充此参数 
        })
        if (res.ErrMsg == '') {
            this.$common.baseSaveToken(res.Result.forendToken);//缓存token
            this.$common.baseSaveRoute(res.Result);//缓存路由
            this.$common.baseSaveSername(res.Result.bizUserInfo.mobile);//缓存用户名
            this.$common.baseSaveID(res.Result.bizUserInfo.id);//缓存商户id

            this.$common.baseSaveSeparate(this.actives);//区分开商户和用户
            this.$router.push({path:'/home'})
             this.$alert('登录成功', '消息提示', {confirmButtonText: '确定'});
        }else{
           this.$alert(res.ErrMsg, {confirmButtonText: '确定'});
        }
        }

    },


    //用户切换
    yonghuqiehuan(){
       this.actives = 1 ,
       this.account = {}
       this.phonecode = {}
    },
    //商户切换
    shanghuqiehuan(){
      this.actives = 2,
      this.account = {}
       this.phonecode = {}
    },
  },
};
</script>

<style  scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #2FB4E3 !important;
  background: url("../assets/image/bgd.png");
  background-size: 100% 100%;
}
.register {
  position: fixed;
  width: 350px;
  height: 500px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  transition: all .5s;
   overflow: hidden;
}
.registers {
  position: fixed;
  width: 0px;
  height: 500px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 111;
   transition: all .5s;
   overflow: hidden;
}


.register-box {
  width: 350px;
  height: 380px;
  background: #fff;
}
.register-box .log {
  width: 100%;
  height: 80px;
  padding: 30px;
  display: flex;
  justify-content: left;
}
.register-box .log img {
  width: 230px;
  height: 30px;
}
.register-box .text {
  height: 80px;
  text-align: center;
  font-size: 20px;
  color: #13abb1;
  font-weight: 600;
}
.forms {
  width: 80%;
  margin: 0 auto;
}
.forms-box {
  width: 100%;
  height: 50px;

}
.forms-box.yanzheng {
  display: flex;
  padding: 0 !important;
  justify-content: space-between;
}
.denglu-biy{
  width: 80px;
  height: 40px;
  font-size: 12px;
  padding: 0;
}
.form-btn{
  width: 100%;
  text-align: center;
}
.form-btn .biy-icon{
  width: 140px;
}
.register-botton {
  width: 350px;
  padding-top: 20px;
  text-align: center;
  color: #777;
}
.register-botton span {
  color: #333;
}
.qiehuan{
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.qiehuan p{
  font-size: 12px;
  width: 120px;
  float: right;
  cursor: pointer;
  transition: all .3s;
}
.qiehuan p:hover{
  color: #13abb1;
}


.bah{
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0px;
}
</style>