const Config = {

	//测试环境
	interfaces:"https://api.yxp1419153.com/ajaxEntryCenter.php?WsAjaxPrj=YXP",
	// interfaces:"https://zpwebapi.kaidekuai.com/ajaxEntryCenter.php?WsAjaxPrj=YXP",

	// 图片地址
	ImgUrl:"https://api.yxp1419153.com",

	//上传图片地址
	uploadingimg:"https://api.yxp1419153.com/ajaxEntryCenter.php?WsAjaxPrj=YXP&WsAjaxBiz=Plater&WsAjaxAction=fileUpload",
}

export default Config