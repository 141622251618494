import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "login",
    component:Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: "/",
    name: "login",
    component:Login,
    meta: {
      title: '登录'
    }
  },
  
  {
    path: "/home",
    name: "Home",
    component:resolve => require(['../views/Home.vue'],resolve),
    meta: {                                    
      requiresAuth: true      // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    children: [
      // 会员管理
      {
        path: '/home/memberlist',
        component:resolve => require(['../views/usercontrol/Memberlist.vue'],resolve),
        meta: { 
        title: '会员列表'},
      },
      {
        path: '/home/Memberlistpar/:id',
        component:resolve => require(['../views/particulars/Memberlistpar.vue'],resolve),
        meta: { title: '会员列表详情页'},
      },
      {
        path: '/home/merchantlist',
        component:resolve => require(['../views/usercontrol/Merchantlist.vue'],resolve),
        meta: {title: '商家列表' }
      },
      {
        path: '/home/merchantlistpar/:id',
        component:resolve => require(['../views/particulars/Merchantlistpar.vue'],resolve),
        meta: { title: '商家列表详情页'},
      },
      {
        path: '/home/unamortized',
        component:resolve => require(['../views/usercontrol/Unamortized.vue'],resolve),
        meta: {title: '待审核商家'}
      },
      {
        path: '/home/unamortizedpar/:id',
        component:resolve => require(['../views/particulars/Unamortizedpar.vue'],resolve),
        meta: { title: '待审核商家详情页'},
      },
      {
        path: '/home/directlymenber/:id',
        component:resolve => require(['../views/usercontrol/Directlymenber.vue'],resolve),
        meta: { title: '直属会员' }
      },
      {
        path: '/home/addfriends',
        component:resolve => require(['../views/usercontrol/Addfriends.vue'],resolve),
        meta: { title: '添加合同内容'}
      },
      {
        path: '/home/agency',
        component:resolve => require(['../views/usercontrol/Agency.vue'],resolve),
        meta: { title: '代理合同列表'}
      },
      {
        path: '/home/agencypar/:id',
        component:resolve => require(['../views/particulars/Agencypar.vue'],resolve),
        meta: { title: '合同签字详情'},
      },
      // 招聘管理
      {
        path: '/home/slideshow',
        component:resolve => require(['../views/invite/Slideshow.vue'],resolve),
        meta: { title: '轮播图'}
      },
      {
        path: '/home/slideshowpar/:id',
        component:resolve => require(['../views/particulars/Slideshowpar.vue'],resolve),
        meta: { title: '轮播图修改'},
      },
      {
        path: '/home/auditserve',
        component:resolve => require(['../views/invite/Auditserve.vue'],resolve),
        meta: { title: '审核招聘信息'}
      },
      {
        path: '/home/auditservepar/:id',
        component:resolve => require(['../views/particulars/Auditservepar.vue'],resolve),
        meta: { title: '待审核招聘详情'},
      },
      {
        path: '/home/lookinvite',
        component:resolve => require(['../views/invite/Lookinvite.vue'],resolve),
        meta: { title: '查看招聘信息'}
      },
      {
        path: '/home/lookinvitepar/:id',
        component:resolve => require(['../views/particulars/Lookinvitepar.vue'],resolve),
        meta: { title: '查看招聘信息'}
      },
      {
        path: '/home/newsinvitepar/:id',
        component:resolve => require(['../views/particulars/Newsinvitepar.vue'],resolve),
        meta: { title: '编辑招聘信息'},
      },
      {
        path: '/home/newsinvite',
        component:resolve => require(['../views/invite/Newsinvite.vue'],resolve),
        meta: { title: '发布招聘信息'}
      },

      {
        path: '/home/brokerage',
        component:resolve => require(['../views/invite/Brokerage.vue'],resolve),
        meta: { title: '佣金设置'}
      },
      {
        path: '/home/levelset',
        component:resolve => require(['../views/invite/Levelset.vue'],resolve),
        meta: { title: '等级设置'}
      },
      {
        path: '/home/levelsetpar/:id',
        component:resolve => require(['../views/particulars/Levelsetpar.vue'],resolve),
        meta: { title: '等级条件修改'}
      },

      // 工单管理
      {
        path: '/home/orderlist',
        component:resolve => require(['../views/workorder/Orderlist.vue'],resolve),
        meta: { title: '订单列表'}
      },
      {
        path: '/home/orderlistpar',
        component:resolve => require(['../views/particulars/Orderlistpar.vue'],resolve),
        meta: { title: '订单列表详情'}
      },
      {
        path: '/home/Workorder',
        component:resolve => require(['../views/workorder/Workorder.vue'],resolve),
        meta: { title: '工单费用'}
      },
      {
        path: '/home/Workorderpar',
        component:resolve => require(['../views/particulars/Workorderpar.vue'],resolve),
        meta: { title: '工单费用详情'}
      },

      {
        path: '/home/commission',
        component:resolve => require(['../views/workorder/Commission.vue'],resolve),
        meta: { title: '佣金提现(财务)'}
      },
      {
        path: '/home/share',
        component:resolve => require(['../views/workorder/Share.vue'],resolve),
        meta: { title: '分享充值'}
      },
      {
        path: '/home/wage',
        component:resolve => require(['../views/workorder/Wage.vue'],resolve),
        meta: { title: '佣金工资待发放'}
      },
      {
        path: '/home/Workorder',
        component:resolve => require(['../views/workorder/Workorder.vue'],resolve),
        meta: { title: '商家费用'}
      },
      {
        path: '/home/wagepar',
        component:resolve => require(['../views/particulars/Wagepar.vue'],resolve),
        meta: { title: '工资确认'}
      },
      {
        path: '/home/voucherlist',
        component:resolve => require(['../views/workorder/Voucherlist.vue'],resolve),
        meta: { title: '凭证列表'}
      },
      {
        path: '/home/upvoucher',
        component:resolve => require(['../views/workorder/Upvoucher.vue'],resolve),
        meta: { title: '上传凭证'}
      },
      {
        path: '/home/managerRevenue',
        component:resolve => require(['../views/workorder/ManagerRevenue.vue'],resolve),
        meta: { title: '经理收益'}
      },
      // 社区会员
      {
        path: '/home/community',
        component:resolve => require(['../views/filelesser/community/Community.vue'],resolve),
        meta: { title: '社区列表信息'}
      },
      // 收藏管理
      {
        path: '/home/collect',
        component:resolve => require(['../views/filelesser/collect/Collect.vue'],resolve),
        meta: { title: '收藏列表'}
      },
      // 简历管理
      {
        path: '/home/resume',
        component:resolve => require(['../views/filelesser/resume/Resume.vue'],resolve),
        meta: { title: '简历列表'}
      },
      {
        path: '/home/resumepar/:id',
        component:resolve => require(['../views/filelesser/resume/Resumepar.vue'],resolve),
        meta: { title: '详情列表'}
      },
      // 投诉管理
      {
        path: '/home/complaint',
        component:resolve => require(['../views/filelesser/complaint/Complaint.vue'],resolve),
        meta: { title: '投诉列表'}
      },
      {
        path: '/home/complaintpar/:id',
        component:resolve => require(['../views/filelesser/complaint/Complaintpar.vue'],resolve),
        meta: { title: '投诉详情'}
      },
      // 通知管理
      {
        path: '/home/notification',
        component:resolve => require(['../views/filelesser/notification/Notification.vue'],resolve),
        meta: { title: '通知列表'}
      },
      {
        path: '/home/newsnotification',
        component:resolve => require(['../views/filelesser/notification/Newsnotification.vue'],resolve),
        meta: { title: '发布通知'}
      },
      {
        path: '/home/dialogue',
        component:resolve => require(['../views/filelesser/notification/Dialogue.vue'],resolve),
        meta: { title: '对话列表'}
      },
      // 统计管理
      {
        path: '/home/statistics',
        component:resolve => require(['../views/filelesser/statistics/Statistics.vue'],resolve),
        meta: { title: '统计'}
      },

      // 设置
      {
        path: '/home/uptype',
        component:resolve => require(['../views/set/Uptype.vue'],resolve),
        meta: { title: '类型管理'}
      },
      {
        path: '/home/issuemessage',
        component:resolve => require(['../views/set/Issuemessage.vue'],resolve),
        meta: { title: '发布平台信息'}
      },
      {
        path: '/home/rolelist',
        component:resolve => require(['../views/set/Rolelist.vue'],resolve),
        meta: { title: '角色列表'}
      },
      {
        path: '/home/rolelistpar/:id',
        component:resolve => require(['../views/particulars/Rolelistpar.vue'],resolve),
        meta: { title: '角色列表详情'}
      },
      {
        path: '/home/operation',
        component:resolve => require(['../views/set/Operation.vue'],resolve),
        meta: { title: '操作指南'}
      },
      {
        path: '/home/poster',
        component:resolve => require(['../views/set/Poster.vue'],resolve),
        meta: { title: '上传海报'}
      },
      {
        path: '/home/subusers',
        component:resolve => require(['../views/set/Subusers.vue'],resolve),
        meta: { 
        title: '管理开通'},
      },
      {
        path: '/home/subuserspar/:id',
        component:resolve => require(['../views/particulars/Subuserspar.vue'],resolve),
        meta: { title: '修改权限'},
      },
      // 修改密码
      {
        path: '/home/resetSelfPwd',
        component:resolve => require(['../views/particulars/ResetSelfPwd.vue'],resolve),
        meta: { title: '修改密码'}
      },

  ]
  },

  
  {
    path: '/about',
    name: 'About',
    component:resolve => require(['../views/About.vue'],resolve),
    meta: {
      title: '优信聘人才平台'
    }
  },
  {
    path: '/jumpapp',
    name: 'Jumpapp',
    component:resolve => require(['../views/Jumpapp.vue'],resolve),
    meta: {
      title: '优信聘人才平台'
    }
  },
]

// const router = new VueRouter({
//   linkActiveClass:'active',
//   mode:'history',
//   routes
// })

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// //获取token方法
//   function baseGetToken(){
//   return window.localStorage.getItem("TOKEN");
//   return window.sessionStorage.getItem("TOKEN");
// }

// 统一对接口回调结果数据进行校验的方法
// function verifyAjaxResult (ajaxResult,handleReLogin){
//   if(typeof(ajaxResult.ErrMsg) == 'undefined' ){//必须返回ErrMsg属性，才算接口正常
//       alert("接口调用失败");//接口调用失败信息提示
//       return false;
//   }else if(ajaxResult.ErrMsg){//ErrMsg属性非空，则接口异常
//       if(handleReLogin && parseInt(ajaxResult.ErrMsg.indexOf("FOREND_TOKEN_INVAILD"))>-1){//登录TOKEN无效异常
//           alert("登录TOKEN无效:"+ajaxResult.ErrMsg);//信息提示  
//           //wx.navigateTo("重定向到获取微信openid的接口/或直接ajax静默获取一下，此接口会返回新的token，然后持久化即可"); 
//           return false;                 
//       }
      
//       alert("接口调用失败:"+ajaxResult.ErrMsg);//接口调用失败信息提示 
//       return false;                 
//   }
//   return true;
// }

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {  // 哪些需要验证
//     if (!baseGetToken()) {  // token存在条件 
//       alert("登录失效，请重新登录")  
//       next({
//         path: '/',            // 验证失败要跳转的页面
//         query: {
//            redirect:"登录失效"   // 要传的参数
//          }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next()  // 确保一定要调用 next()
//   }
// })

export default router
