import axios from 'axios';

axios.defaults.timeout = 100000

//拦截器处理
axios.interceptors.request.use(
	config => {
		config.headers['Content-Type'] = `application/json;charset=UTF-8`
		// if(token && token!=''){
		// 	config.headers['token'] = localStorage.getItem("token");
		// }
		// if (config.method === 'get') {
		// 	config.params = {
		// 		...config.data,
		// 		_t: Date.parse(new Date()) / 1000
		// 	}
		// console.info("请求拦截器>>>>>>>>>>",config)
		return config
	}
)

//请求返回数据处理
axios.interceptors.response.use(response => {
	// console.info('requert 请求 Start----------------------------------------------')
	// console.info('请求接口 ', response.data)
	
	// console.info('requert 请求 End----------------------------------------------')
	if (response.status == '200') {
		return response.data
	} else {
		switch (response.status) {
			case 401:
				alert('登录超时,请重新登录')
			case 404:
				alert('接口地址错误')
			case 500:
				alert('服务器错误')
			default: {
				alert('接口地址错误')
			}
		}
	}
})

export default {
	async get(url, params) {
		let res = null;
		if (!params) {
			res = await axios.get(url)
		} else {
			res = await axios.get(url + '?' + params)
		}
		return res
	},
	async post(url, params) {
		let res = await axios.post(url, params)
		return res
	}
}  