  



const globalObj = {}
  
//   统一的持久化token方法
globalObj.baseSaveToken = function (token){
    window.localStorage.setItem("TOKEN",token);
    window.sessionStorage.setItem("TOKEN",token);
}
//统一获取token方法
globalObj.baseGetToken = function(){
    return window.localStorage.getItem("TOKEN");
    return window.sessionStorage.getItem("TOKEN");
}



// 持久化用户名账号的方法
globalObj.baseSaveSername = function (username){
    window.localStorage.setItem("USERNAME",JSON.stringify(username));
    window.sessionStorage.setItem("USERNAME",JSON.stringify(username));
}
globalObj.baseGetuSername = function(){
    return JSON.parse(window.localStorage.getItem("USERNAME"));
    return JSON.parse(window.sessionStorage.getItem("USERNAME"));
}


// 持久化路由的方法
globalObj.baseSaveRoute = function (route){
    window.localStorage.setItem("ROUTE",JSON.stringify(route));
    window.sessionStorage.setItem("ROUTE",JSON.stringify(route));
}
globalObj.baseGetRoute = function(){
    return JSON.parse(window.localStorage.getItem("ROUTE"));
    return JSON.parse(window.sessionStorage.getItem("ROUTE"));
}



// 持久化区分商户和用户的方法 1是用户登录 2是商户登录
globalObj.baseSaveSeparate = function (separate){
    window.localStorage.setItem("SEPARATE",JSON.stringify(separate));
    window.sessionStorage.setItem("SEPARATE",JSON.stringify(separate));
}
globalObj.baseGetSeparate = function(){
    return JSON.parse(window.localStorage.getItem("SEPARATE"));
    return JSON.parse(window.sessionStorage.getItem("SEPARATE"));
}

// 持久化登录密码
globalObj.baseSavePassword = function (password){
    window.localStorage.setItem("PASSWORD",JSON.stringify(password));
    window.sessionStorage.setItem("PASSWORD",JSON.stringify(password));
}
globalObj.baseGetPassword = function(){
    return JSON.parse(window.localStorage.getItem("PASSWORD"));
    return JSON.parse(window.sessionStorage.getItem("PASSWORD"));
}


// 持久化商家ID
globalObj.baseSaveID = function (id){
    window.localStorage.setItem("ID",JSON.stringify(id));
    window.sessionStorage.setItem("ID",JSON.stringify(id));
}
globalObj.baseGetID = function(){
    return JSON.parse(window.localStorage.getItem("ID"));
    return JSON.parse(window.sessionStorage.getItem("ID"));
}







// 统一对接口回调结果数据进行校验的方法
globalObj.verifyAjaxResult = function(ajaxResult,handleReLogin){
    if(typeof(ajaxResult.ErrMsg) == 'undefined' ){//必须返回ErrMsg属性，才算接口正常
        alert("接口调用失败");//接口调用失败信息提示
        return false;
    }else if(ajaxResult.ErrMsg){//ErrMsg属性非空，则接口异常
        if(parseInt(ajaxResult.ErrMsg.indexOf("FOREND_TOKEN_INVAILD"))>-1){//登录TOKEN无效异常
            window.location.href="https://admin.yxp1419153.com";
            alert("登录失效:"+ajaxResult.ErrMsg);//信息提示  
            return false;                 
        }
        alert( ajaxResult.ErrMsg);//接口调用失败信息提示 
        return false;                 
    }
    return true;
}


globalObj.postExcelFile = function(params, url) {    
    var form = document.createElement("form");
    form.style.display = "none";
    form.action = url;
    form.method = "post";
    document.body.appendChild(form);    
    for (var key in params) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = params[key];
      form.appendChild(input);
    }
    form.submit();
    form.remove();
  }

globalObj.loading = true

export default globalObj